















































import { Component, Vue } from "vue-property-decorator";
import router from "../router";
import { REST } from '../_util/rest_call'

@Component({
  name: "ForgotPassword",
  data() {
    return {
      userEmail: '',
      forgotPassword: '',
    };
  },
  computed: {
    emptyEmail() {
      return this.$data.userEmail.trim().length == 0;
    },
  },
  methods: {
    backToLogin() {
      REST.logDebug("registerUser");
      router.push({ path: "/login" });
    },
  },
})
export default class ForgotPassword extends Vue {}
